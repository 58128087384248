.logo {
    width: 200px;
    height: 200px;
}

.container {
    display: flex;
    flex: 3;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-width: 100vw;
    height: auto;
    margin: 0px;
    padding: 0px;
}

.header {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: auto;
    padding: 60px;
}

.info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: auto;
    width: 85%;
}

.info .deactivated {
    text-align: center;
}

@media only screen and (min-width: 768px) {
    .info {
        width: 50%;
    }
}

.info .slogan {
    text-align: center;
    width: 100%;
    margin: auto auto 10px;
}

.userPhoto {
    width: 100px;
    height: 100px;
}

.foot {
    width: 100%;
    height: 100px;
    position: relative;
    left: 0px;
    right: 0px;
    bottom: 0px;
    top: auto;
    color: black;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: sans-serif;
}

tr {
    margin: 10px;
}

th {
    font-weight: 800;
    font-family: sans-serif;
    font-size: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

td {
    font-family: sans-serif;
    font-size: 18px;
    text-align: center;
}

p {
    font-family: sans-serif;
}

h1 {
    font-family: sans-serif;
}

h2 {
    font-family: sans-serif;
}

h3 {
    font-family: sans-serif;
    font-style: italic;
}

.inputDiv {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin: 10px;
}

label {
    font-family: sans-serif;
    font-size: 20px;
    font-weight: 700;
}

input {
    font-family: sans-serif;
    width: 350px;
    height: 40px;
    font-size: 18px;
    border-radius: 5px;
}

input[type="radio"] {
    font-family: sans-serif;
    width: 20px;
    height: 20px;
    font-size: 18px;
    border-radius: 5px;
}

textarea {
    font-family: sans-serif;
    width: 350px;
    height: 60px;
    font-size: 18px;
    border-radius: 5px;
}

button {
    font-family: sans-serif;
    width: 350px;
    height: 40px;
    font-size: 18px;
    background: green;
    color: aliceblue;
    border-color: transparent;
    border-radius: 5px;
    margin-top: 10px;
}

.fade-in {
    animation: fadeIn ease 10s;
    -webkit-animation: fadeIn ease 7.5s infinite;
    -moz-animation: fadeIn ease 7.5s infinite;
    -o-animation: fadeIn ease 7.5s infinite;
    -ms-animation: fadeIn ease 7.5s infinite;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.fade-in2 {
    animation: fadeIn ease 10s;
    -webkit-animation: fadeIn ease 10.5s infinite;
    -moz-animation: fadeIn ease 10.5s infinite;
    -o-animation: fadeIn ease 10.5s infinite;
    -ms-animation: fadeIn ease 10.5s infinite;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


.MuiButtonBase-root {
width:100px
}

.spinner {
    margin: auto;
  }


  .MuiAppBar-colorPrimary {
    color: #fff !important;
    background-color: #1976d2 !important;
}

.whiteCss {
    color: #fff !important;
}

.greenCss {
    color: #29f400 !important;
   }

   .redCss {
    color: #ff0606 !important;
   }

.MuiIconButton-colorInherit{
    width: 40px  !important;
}

.form-transform{
    text-transform:capitalize
}

.line-break {
    width: 100%;
}